// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-posts-jwt-vs-sessions-jwt-vs-sessions-mdx": () => import("./../src/posts/jwt-vs-sessions/jwt-vs-sessions.mdx" /* webpackChunkName: "component---src-posts-jwt-vs-sessions-jwt-vs-sessions-mdx" */),
  "component---src-posts-routines-channels-golang-routines-channels-golang-mdx": () => import("./../src/posts/routines-channels-golang/routines-channels-golang.mdx" /* webpackChunkName: "component---src-posts-routines-channels-golang-routines-channels-golang-mdx" */),
  "component---src-posts-types-vs-interfaces-types-vs-interfaces-mdx": () => import("./../src/posts/types-vs-interfaces/types-vs-interfaces.mdx" /* webpackChunkName: "component---src-posts-types-vs-interfaces-types-vs-interfaces-mdx" */)
}

