import { graphql } from "gatsby";
import Img from "gatsby-image";
import PageQuoteText from "utils/pageQuoteText";
import WhatAreDifferences from "posts/types-vs-interfaces/components/WhatAreDifferences";
import DifferenceHeader from "posts/types-vs-interfaces/components/DifferenceHeader";
import Conclusion from "posts/types-vs-interfaces/components/Conclusion";
import * as React from 'react';
export default {
  graphql,
  Img,
  PageQuoteText,
  WhatAreDifferences,
  DifferenceHeader,
  Conclusion,
  React
};