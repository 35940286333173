import { graphql } from "gatsby";
import Img from "gatsby-image";
import PageQuoteText from "utils/pageQuoteText";
import Differences from "posts/jwt-vs-sessions/components/Differences";
import HeaderWrapper from "posts/jwt-vs-sessions/components/HeaderWrapper";
import DiagramImage from "posts/jwt-vs-sessions/components/DiagramImage";
import Jwt from "../../../../src/posts/jwt-vs-sessions/images/jwt-diagram.png";
import Sessions from "../../../../src/posts/jwt-vs-sessions/images/sessions-diagram.png";
import * as React from 'react';
export default {
  graphql,
  Img,
  PageQuoteText,
  Differences,
  HeaderWrapper,
  DiagramImage,
  Jwt,
  Sessions,
  React
};