import { graphql } from "gatsby";
import Img from "gatsby-image";
import WhatIsRoutine from "posts/routines-channels-golang/components/WhatIsRoutine";
import PageQuoteText from "utils/pageQuoteText";
import HowToMakeRoutine from "posts/routines-channels-golang/components/HowToMakeRoutine";
import AllowingCommunication from "posts/routines-channels-golang/components/AllowingCommunication";
import RoutineChannelsFinished from "posts/routines-channels-golang/components/RoutineChannelsFinished";
import * as React from 'react';
export default {
  graphql,
  Img,
  WhatIsRoutine,
  PageQuoteText,
  HowToMakeRoutine,
  AllowingCommunication,
  RoutineChannelsFinished,
  React
};